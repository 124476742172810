<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-color: #D1D1D1"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">SEAM SEALING TAPE FOR CR SPONGE</h1>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">Product Descriptions</h2>
              <h5 class="description text-left">
                Seam Sealing Tape for CR Sponge (Neoprene™)
              </h5>
              <h5 class="description text-left">BT960</h5>
              <h5 class="description text-left">
                The solvent-free hot-melt adhesive layer is environmentally
                friendly and has more stable adhesive properties. It is suitable
                for use on elastic material and CR sponge (Neoprene™). It has
                excellent elastic recovery, which can completely seal the
                pinhole, prevent friction between the skin and the stiching, and
                can also prevent water leakage.
              </h5>
              <h5 class="text-left">
                <pre class="description" style="font-family: Arial">
Especially for:
  ∙ Wetsuit
  ∙ Finshing bib
  ∙ Farm boots
  ∙ Cycling shoes
  ∙ Surfing boots
  ∙ Other outdoor gears
                </pre>
              </h5>
              <h5 class="description text-left">
                Zhilun can provide different widths and thicknesses according to
                your needs, conducts sample and test with seam sealing machine,
                waterproof testing machine, etc.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
export default {
  name: "product_three",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
    };
  },
};
</script>
<style></style>
