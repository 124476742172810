<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-color: #D1D1D1"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">SEAM SEALING TAPE FOR SHOES</h1>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">Product Descriptions</h2>
              <h5 class="description text-left">Seam Sealing Tape for Shoes</h5>
              <h5 class="description text-left">ST1670</h5>
              <h5 class="description text-left">
                The solvent-free hot-melt adhesive layer is environmentally
                friendly and has more stable adhesive properties. It is suitable
                for use on the lining or upper of waterproof shoes, which can
                completely seal the pinholes and prevent water leakage.
              </h5>
              <h5 class="text-left">
                <pre class="description" style="font-family: Arial">
Especially for:
  ∙ Hiking boots
  ∙ Snow boots
  ∙ Ski boots
  ∙ Golf shoes
  ∙ Safety shoes
  ∙ Motorcycle boots
  ∙ Military boots
  ∙ Tactical boots
  ∙ Other waterproof foorwear and gears
                </pre>
              </h5>
              <h5 class="description text-left">
                This tape has the fabric layer is easier and faster to use.
                Zhilun provides different widths and thicknesses, conducts
                sample and test with seam sealing machine, waterproof testing
                machine, etc.
              </h5>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h5 class="description text-left">Seam Sealing Tape for Shoes</h5>
              <h5 class="description text-left">CT830</h5>
              <h5 class="description text-left">
                The solvent-free hot-melt adhesive layer is environmentally
                friendly and has more stable adhesive properties. It is suitable
                for use on the lining or upper of waterproof shoes, which can
                completely seal the pinholes and prevent water leakage.
              </h5>
              <h5 class="text-left">
                <pre class="description" style="font-family: Arial">
Especially for:
  ∙ Hiking boots
  ∙ Snow boots
  ∙ Ski boots
  ∙ Golf shoes
  ∙ Safety shoes
  ∙ Motorcycle boots
  ∙ Military boots
  ∙ Tactical boots
  ∙ Other waterproof foorwear and gears
                </pre>
              </h5>
              <h5 class="description text-left">
                Choosing this tape without the fabric layer is more cost
                effective. Zhilun provides different widths and thicknesses,
                conducts sample and test with seam sealing machine, waterproof
                testing machine, etc.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
export default {
  name: "product_one",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
    };
  },
};
</script>
<style></style>
