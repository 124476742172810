<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-color: #D1D1D1"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">OTHER HOT MELT PRODUCTS</h1>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">Product Descriptions</h2>
              <h5 class="description text-left">
                Hot Melt Adhesive Film The solvent-free hot-melt adhesive layer
                is environmentally friendly and has more stable adhesive
                properties. It has been widely used in shoes, clothing, bag,
                hat, leather, sporting, surfing, fishing, diving, climbing,
                hiking, outdoor etc. Zhilun provides different specification and
                0.4mm~ 0.24mm thickness, you can discuss with us and choose
                according to your needs.
              </h5>
            </div>
          </div>
          <div class="row">
            <table class="table table-striped">
              <thead class="thead-dark">
                <tr class="table-dark">
                  <th scope="col" colspan="9">Technical Data Sheet</th>
                </tr>
              </thead>
              <tbody>
                <tr style="background-color: #09A1A6; color: white;">
                  <th scope="row">Item No.</th>
                  <td>HM281</td>
                  <td>HM284A</td>
                  <td>HM183A</td>
                  <td>HM293</td>
                  <td>HM190</td>
                  <td>HM177BX</td>
                  <td>HM-174</td>
                  <td>HM175F</td>
                </tr>
                <tr style="background-color: #807e7e; color: white;">
                  <th scope="col" colspan="9" class="table-active">
                    Physical Property
                  </th>
                </tr>
                <tr>
                  <th scope="row">Appearance</th>
                  <td>Transparent</td>
                  <td>Transparent</td>
                  <td>Transparent</td>
                  <td>Translucent</td>
                  <td>Translucent</td>
                  <td>Translucent</td>
                  <td>Transparent</td>
                  <td>Translucent</td>
                </tr>
                <tr>
                  <th scope="row">Releasing Paper</th>
                  <td>Release paper</td>
                  <td>Release paper</td>
                  <td>None</td>
                  <td>Release paper</td>
                  <td>Release paper</td>
                  <td>PET film</td>
                  <td>Release paper</td>
                  <td>None</td>
                </tr>
                <tr>
                  <th scope="row">Thickness</th>
                  <td colspan="8" class="text-center">
                    0.4mm, 0.8mm, 0.12mm, 0.16mm, 0.2mm, 0.24mm etc.
                  </td>
                </tr>
                <tr>
                  <th scope="row">Flowing Temperature</th>
                  <td>96±5 °C</td>
                  <td>118±5 °C</td>
                  <td>98±5 °C</td>
                  <td>90±5 °C</td>
                  <td>60±7 °C</td>
                  <td>116±5 °C</td>
                  <td>115±5 °C</td>
                  <td>85±5 °C</td>
                </tr>
                <tr>
                  <th scope="row">Specific Gravity</th>
                  <td>1.2 g/cm³</td>
                  <td>1.2 g/cm³</td>
                  <td>1.2 g/cm³</td>
                  <td>1.2 g/cm³</td>
                  <td>1.2 g/cm³</td>
                  <td>0.9 g/cm³</td>
                  <td>0.9 g/cm³</td>
                  <td>0.9 g/cm³</td>
                </tr>
                <tr>
                  <th scope="row">Hardness</th>
                  <td>82±3 A</td>
                  <td>80±2 A</td>
                  <td>82±3 A</td>
                  <td>75±3 A</td>
                  <td>95±3 A</td>
                  <td>81±3 A</td>
                  <td>84±3 A</td>
                  <td>88±3 A</td>
                </tr>
                <tr>
                  <th scope="row">Solvent</th>
                  <td>None</td>
                  <td>None</td>
                  <td>None</td>
                  <td>None</td>
                  <td>None</td>
                  <td>None</td>
                  <td>None</td>
                  <td>None</td>
                </tr>
                <tr>
                  <th scope="row">Shelf Life</th>
                  <td>1 year</td>
                  <td>1 year</td>
                  <td>1 year</td>
                  <td>1 year</td>
                  <td>1 year</td>
                  <td>1 year</td>
                  <td>1 year</td>
                  <td>1 year</td>
                </tr>
                <tr style="background-color: #807e7e; color: white;">
                  <th scope="col" colspan="9" class="table-active">
                    Characteristics
                  </th>
                </tr>
                <tr>
                  <th scope="row">Elastic Response</th>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                  <td></td>
                  <td></td>
                  <td>●</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">Heat Resistant</th>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                  <td></td>
                  <td></td>
                  <td>●</td>
                  <td>●</td>
                  <td>●</td>
                </tr>
                <tr>
                  <th scope="row">Yellowing Resistant</th>
                  <td>●</td>
                  <td></td>
                  <td></td>
                  <td>●</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">Hydrolysis Resistant</th>
                  <td>●</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">Bonded with Water Repellent Coatings</th>
                  <td>●</td>
                  <td>●</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">Suitable Items</th>
                  <td>
                    For upper, inner lining, reinforcement, fabric, TPU, PU
                    leather, etc
                  </td>
                  <td>
                    For upper, inner lining, reinforcement, fabric, TPU, PU
                    leather, etc
                  </td>
                  <td>
                    For upper, inner lining, reinforcement, fabric, TPU, PU
                    leather, etc
                  </td>
                  <td>
                    For upper, inner lining, reinforcement, fabric, TPU, PU
                    leather, etc
                  </td>
                  <td>
                    For upper, inner lining, reinforcement, fabric, TPU, PU
                    leather, etc
                  </td>
                  <td>For sole, Fabric, Rubber, TPR, EVA, etc</td>
                  <td>For sole, Fabric, Rubber, TPR, EVA, Leather, etc</td>
                  <td>For insole, Fabric, Neoprene, EVA Foam, etc</td>
                </tr>
                <tr style="background-color: #807e7e; color: white;">
                  <th scope="col" colspan="9" class="table-active">
                    Operation suggestions
                  </th>
                </tr>
                <tr>
                  <th scope="row">Operation method</th>
                  <td>Flat plate hot pressing</td>
                  <td>Flat plate hot pressing</td>
                  <td>Flat plate hot pressing</td>
                  <td>Flat plate hot pressing</td>
                  <td>Flat plate hot pressing</td>
                  <td>Flat plate hot pressing</td>
                  <td>Flat plate hot pressing</td>
                  <td>Roller hot pressing</td>
                </tr>
                <tr>
                  <th scope="row">Temperature setting</th>
                  <td>129 ~160 ℃</td>
                  <td>109 ~140 ℃</td>
                  <td>129 ~160 ℃</td>
                  <td>110 ~140 ℃</td>
                  <td>110 ~140 ℃</td>
                  <td>129 ~170 ℃</td>
                  <td>120 ~150 ℃</td>
                  <td>99 ~130 ℃</td>
                </tr>
                <tr>
                  <th scope="row">Pressure Setting</th>
                  <td>9.8 ㎏f/cm²</td>
                  <td>9.8 ㎏f/cm²</td>
                  <td>9.8 ㎏f/cm²</td>
                  <td>9.8 ㎏f/cm²</td>
                  <td>9.8 ㎏f/cm²</td>
                  <td>4.9 ㎏f/cm²</td>
                  <td>9.8 ㎏f/cm²</td>
                  <td>4.9 ㎏f/cm²</td>
                </tr>
                <tr>
                  <th scope="row">Dwell Time</th>
                  <td>More than 10 sec</td>
                  <td>More than 10 sec</td>
                  <td>More than 10 sec</td>
                  <td>More than 10 sec</td>
                  <td>More than 10 sec</td>
                  <td>More than 20 sec</td>
                  <td>More than 10 sec</td>
                  <td>More than 20 sec</td>
                </tr>
                <tr>
                  <th scope="col" colspan="9" class="table-active">
                    * The above suitable items and operation suggestions are for
                    reference only. It must be adjusted according to factors
                    such as the actual materials, processing machines,
                    processing procedures, etc. It is recommended to select
                    after discussing with our technical staff.
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
export default {
  name: "product_five",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
    };
  },
};
</script>
<style></style>
