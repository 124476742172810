<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-color: #D1D1D1"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <alert v-if="formAlert" type="info">
            <button
              @click="resetToDefault"
              type="button"
              class="close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <strong>Contact Us Successfully!</strong>
            We will get back to you as soon as possible.
          </alert>
          <alert v-if="inputAlert" type="danger">
            <button
              @click="resetToDefault"
              type="button"
              class="close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <strong>Please Check Inputs!</strong>
          </alert>
          <form @submit.prevent="sendEmail">
            <div class="form-group">
              <label class="font-weight-bold" for="customer_name">Name</label>
              <input
                type="text"
                class="form-control"
                id="customer_name"
                name="customer_name"
                placeholder="Name"
                v-model="formData.name"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-bold" for="customer_email">Email</label>
              <input
                type="email"
                class="form-control"
                id="customer_email"
                name="customer_email"
                placeholder="name@example.com"
                v-model="formData.email"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-bold" for="message">Message</label>
              <textarea
                class="form-control"
                id="message"
                name="message"
                rows="3"
                v-model="formData.message"
              ></textarea>
            </div>
            <button type="submit" class="btn btn-info mb-2">Send</button>
          </form>
        </div>
      </div>
    </div>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3638.646095891141!2d120.63211057611588!3d24.219171370617115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346917ed287125b5%3A0xda31d2e51c550ad4!2z6Ie057a45pyJ6ZmQ5YWs5Y-4!5e0!3m2!1szh-TW!2stw!4v1694839279355!5m2!1szh-TW!2stw"
      width="100%"
      height="380"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</template>
<script>
import emailjs from "emailjs-com";
import { Alert } from "@/components";
export default {
  name: "landing-page",
  bodyClass: "landing-page",
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
      formAlert: false,
      inputAlert: false,
    };
  },

  components: {
    Alert,
  },

  methods: {
    async sendEmail(e) {
      try {
        // 检查是否有空值
        if (
          !this.formData.name ||
          !this.formData.email ||
          !this.formData.message
        ) 
        {
          this.inputAlert = true;
          return;
        }
        else
        {
          this.inputAlert = false;
        }

        const result = await emailjs.sendForm(
          "default_service",
          "template_rptg5vn",
          e.target,
          "ez96iiuBj53oojoot"
        );
        console.log("SUCCESS!", result.status, result.text);
      } catch (error) {
        console.log("FAILED...", error);
      } finally {
        if (this.inputAlert !== true)
        {
          this.formAlert = true;
          this.formData = {
            name: "",
            email: "",
            message: "",
          };
        }
      }
    },

    resetToDefault() {
      this.formAlert = false;
      this.inputAlert = false;
    },
  },
};
</script>
<style></style>
