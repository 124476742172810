import Vue from "vue";
import Router from "vue-router";

import Index from "./pages/StarterPage.vue";
import StarterNavbar from "./layout/StarterNavbar.vue";
import StarterFooter from "./layout/StarterFooter.vue";
import Landing from './pages/Landing.vue';
import ProductOne from './pages/ProductOne.vue';
import ProductTwo from './pages/ProductTwo.vue';
import ProductThree from './pages/ProductThree.vue';
import ProductFour from './pages/ProductFour.vue';
import ProductFive from './pages/ProductFive.vue';

import About from "./pages/About.vue";

Vue.use(Router);

export default new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: "/",
      name: "index",
      components: {
        default: Index,
        header: StarterNavbar,
        footer: StarterFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/contact",
      name: "about",
      components: {
        default: About,
        header: StarterNavbar,
        footer: StarterFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: '/seam-sealing-tape-for-shoes',
      name: 'product_one',
      components: {
        default: ProductOne,
        header: StarterNavbar,
        footer: StarterFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: '/seam-sealing-tape-for-clothing',
      name: 'product_two',
      components: {
        default: ProductTwo,
        header: StarterNavbar,
        footer: StarterFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: '/seam-sealing-tape-for-cr-sponge',
      name: 'product_three',
      components: {
        default: ProductThree,
        header: StarterNavbar,
        footer: StarterFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: '/seam-sealing-tape-with-high-strength',
      name: 'product_four',
      components: {
        default: ProductFour,
        header: StarterNavbar,
        footer: StarterFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: '/other-hot-melt-products',
      name: 'product_five',
      components: {
        default: ProductFive,
        header: StarterNavbar,
        footer: StarterFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
