<template >
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <router-link v-popover:popover1 class="navbar-brand" to="/">
          <img class="logo" src="../../public/zhilun_mini_logo.png" alt="ZHILUN Logo">
        </router-link>
      </nav>
      <h6>ZHILUN LIMITED COMPANY</h6>
      <nav>
        <ul class="list-group"> 
          <li>
            | TEL: +886 4 2567 3758
          </li>
          <li>
            | FAX: +886 4 2567 3759
          </li>
          <li>
            | No. 99-2, ShangShan Rd., Daya Dist., Taichung City 428015, Taiwan
          </li>
        </ul>
      </nav>
      <nav>
        <ul class="list-group"> 
          <li>
            | Email: info@zhilun-tw.com
          </li>
          <li>
            | www.zhilun-tw.com
          </li>
        </ul>
      </nav>
      <br>
      <br>
      <div class="copyright">
        Copyright © 2023 和順資訊企業社 All rights reserved.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
.logo {
  width: 80px; /* 设置宽度 */
  height: auto; /* 设置高度自动适应宽度 */
}
</style>
