<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-color: #D1D1D1"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <div class="content-center brand">
          <!-- <img class="n-logo" src="img/LOGO.png" alt="" /> -->
          <h1 class="h1-seo">ZHILUN LIMITED COMPANY</h1>
          <p class="mb-0 font-weight-bold">FUNCTIONAL</p>
          <p class="mb-0">Seam Sealing Tapes</p>
          <p class="mb-0">High Strength Tape</p>
          <p class="mb-0">Waterproof & Breathable</p>
          <p class="mb-0">Hot Melt Adhesive Materials for Seamless</p>
          <p class="mb-0">Laminating</p>
          <p class="mb-0">Customized Functional Materials</p>
          <p class="mb-0">Professional Material Consultant</p>
        </div>
          <div class="text-center">
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center" style=" background-size: cover;和background-position: center; background-image: url('img/home_background.jpg')">
            <h2 class="title" style="color: white;">Who we are?</h2>
            <h5 class="description" style="color: white;">
              <p align="left">
                ZHILUN LTD. is a company specializing waterproof and breathable materials, including sales and technical services of waterproof fabrics, seam sealing tapes, hot-melt adhesives, test tapes, and lamination and thermal processing.
              </p>
              <p align="left">
                Our company's main materials are supplied to markets such as shoe materials, clothing materials, bag materials, functional fitness accessories & equipment, industrial materials, etc., which can meet the needs of the industry for waterproof, washing resistance, high temperature resistance, high-strength bonding, etc. and other different feature requirements.
              </p>
              <p align="left">
                In addition to having over 30 years of rich experience in this professional field. The most important thing is to maintain the concept of innovation, pragmatism and service. We also continue to develop new materials and have leading processing technologies in this industry. Building the bridges between upstream and downstream to meet the development and processing needs of customers, and also promote the development and sales of raw material supplier.
              </p>
            </h5>
          </div>
        </div>
        <!-- <div class="separator separator-primary">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">ZHILUN LIMITED COMPANY</h2>
          </div>
          <div class="description col-md-8 ml-auto mr-auto text-center font-weight-bold">
            TEL: +886 4 2567 3758 | FAX: +886 4 2567 3759 | Email: info@zhilun-tw.com | www.zhilun-tw.com
            No. 99-2, ShangShan Rd., Daya Dist., Taichung City 428015, Taiwan
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style>
</style>
