<template>
  <navbar
    position="fixed"
    type="default"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
    style="font-family: Arial;"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img class="logo" src="../../public/zhilun_mini_logo.png" alt="ZHILUN Logo">
      </router-link>
      
      <!-- <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">
          Professional waterproof and breathable material
        </div>
      </el-popover> -->
    </template>
    <template slot="navbar-menu">
      <i class="icon mt-2"></i>
      <drop-down
        tag="li"
        title="Product"
        class="pr-0"
      >
        <nav-link to="/seam-sealing-tape-for-shoes">
          <i class="icon"></i> SEAM SEALING TAPE FOR SHOES
        </nav-link>
        <nav-link to="/seam-sealing-tape-for-clothing">
          <i class="icon"></i> SEAM SEALING TAPE FOR CLOTHING
        </nav-link>
        <nav-link to="/seam-sealing-tape-for-cr-sponge">
          <i class="icon"></i> SEAM SEALING TAPE FOR CR SPONGE
        </nav-link>
        <nav-link to="/seam-sealing-tape-with-high-strength">
          <i class="icon"></i> SEAM SEALING TAPE WITH HIGH STRENGTH
        </nav-link>
        <nav-link to="/other-hot-melt-products">
          <i class="icon"></i> OTHER HOT MELT PRODUCTS
        </nav-link>
      </drop-down>

      <!-- <li class="">
        <a
          class="nav-link"
          to="/contact"
        >
          <i class="now-ui-icons business_globe"></i>
          <p>CONTACT</p>
        </a>
      </li> -->
      <li class="">
        <router-link
          class="nav-link"
          to="/contact"
        >
          <i class="now-ui-icons business_globe"></i>
          <p>CONTACT</p>
        </router-link>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover
  }
};
</script>

<style scoped>
.logo {
  width: 76px; /* 设置宽度 */
  height: auto; /* 设置高度自动适应宽度 */
}

.icon {
  width: 18px; /* 设置图标宽度 */
  height: 18px; /* 设置图标高度 */
  background-image: url('../../public/Icon.png'); /* 设置图像路径 */
  background-size: cover; /* 根据需要调整图像大小 */
}

.navbar {
    text-transform: uppercase;
    font-size: 1.2em;
    padding: 0.5rem 0.5rem;
    line-height: 1.3rem;
}
</style>
